<!--商品-->
<template lang="">
<div style="height:100%;padding:0 20px 10px;box-sizing:border-box">
    <div class="selectionBar">
        <div class="flex flex-ai-c flex-wrap">
            <label>场景：</label>
            <!-- <div class="flex flex-ai-c">
                <el-input v-model="start" placeholder="排行" style="width:100px;"></el-input>
                -
                <el-input v-model="end" placeholder="区间" style="width:100px;" @input="getData(1,10)"></el-input>
            </div> -->
            <div class="flex flex-ai-c" style="marginLeft:auto">
                <el-radio-group v-model="radio" style="margin-right:10px">
                    <el-radio label="year">年</el-radio>
                    <el-radio label="month">月</el-radio>
                    <el-radio label="week">周</el-radio>
                </el-radio-group>

                <el-date-picker v-model="inputyear" size="mini" type="year" :clearable="false" value-format="yyyy" format="yyyy年" placeholder="选择年" @change="getData(1,10)" v-show="radio=='year'">
                </el-date-picker>

                <el-date-picker v-model="inputmonth" size="mini" type="month" :clearable="false" value-format="yyyy-MM-dd" format="yyyy年MM月" placeholder="选择月" @change="getData(1,10)" v-show="radio=='month'">
                </el-date-picker>

                <el-date-picker v-model="inputweek" size="mini" type="week" :clearable="false" value-format="yyyy-MM-dd" format="yyyy 第 WW 周" placeholder="选择周" @change="getData(1,10)" v-show="radio=='week'">
                </el-date-picker>
            </div>
        </div>
    </div>
    <!-- 表格 -->
    <div class="table" style="height:calc( 100% - 160px );overflow-y:auto">
        <el-row :gutter="10" style="height:100%">
            <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4" style="height:100%;" v-for="(item,index) in detail" :key="index">
                <section>
                    <p class="nameD">{{item.name}}</p>
                    <el-table ref="table" :data="item.list.data" highlight-current-row :cell-style="{color:'#515a6e',borderColor:'#E8EAEC'}" :header-cell-style="{background:'#F8F8F9',color:'#515A6E'}" @sort-change='getList()' height="calc( 100% - 70px )">
                        <el-table-column prop="name" label="客户名称" align="center" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="total" label="金额" align="center" show-overflow-tooltip></el-table-column>
                    </el-table>
                    <!-- 分页 -->
                    <div class="paging" style="padding:0">
                        <el-pagination @current-change="currentChange($event,10,item.cate_id)" :current-page="item.list.curr" :page-size="item.list.row" background layout="total, prev, next" :total="item.list.count"></el-pagination>
                    </div>
                </section>
            </el-col>
        </el-row>
    </div>

</div>
</template>

<script>
export default {
    data: function() {
        return {
            radio: 'month', //年月周-默认周
            input: '', //时间选择框
            inputyear: '',
            inputmonth: '',
            inputweek: '',
            inputquarter: '', //季度
            detail: [],
            curr: 1, //页码
            row: 10, //每页条数
            pages: 1, //总页数
            count: 0, //总条数
            start:'',
            end:''
        }
    },
    mounted: function() {
        this.init();
    },
    watch: {
        'radio': function() {
            if (this.radio == 'month') {
                var myDate = new Date();
                var currentMonth1 = myDate.getMonth();
                var firstDay2 = new Date(myDate.getFullYear(), currentMonth1, 1)
                this.inputmonth = this.dataFormat(firstDay2);
            } else if (this.radio == 'week') {
                var current = new Date();
                var currentyear = current.getFullYear();
                var currentMonth2 = current.getMonth();
                var currentDay = current.getDay();
                var currentDate = current.getDate();
                var firstweek = new Date(currentyear, currentMonth2, currentDate - currentDay + 1);
                this.inputweek = this.dataFormat(firstweek);
            } else if (this.radio == 'year') {
                var myDate2 = new Date();
                this.inputyear = String(myDate2.getFullYear());
            } else if (this.radio == 'quarter') {
                var date2 = new Date();
                var nowYear = date2.getFullYear();
                var nowMonth = date2.getMonth(); //当前月
                var quarterStartMonth = 0;
                if (nowMonth < 3) {
                    quarterStartMonth = 1;
                } else if (2 < nowMonth && nowMonth < 6) {
                    quarterStartMonth = 2;
                } else if (5 < nowMonth && nowMonth < 9) {
                    quarterStartMonth = 3;
                } else if (nowMonth > 8) {
                    quarterStartMonth = 4;
                }
                this.defaultValue = nowYear + '-' + quarterStartMonth;
                this.inputquarter = nowYear + '-' + quarterStartMonth;
                this.bus.$on('quarterchange', (value) => {
                    this.inputquarter = value;
                    this.getData(1,10);
                })
            }
            this.getData(1,10);
        }
    },
    methods: {
        init: function() {
            if (this.radio == 'month') {
                var myDate = new Date();
                var currentMonth3 = myDate.getMonth();
                var firstDay2 = new Date(myDate.getFullYear(), currentMonth3, 1)
                this.inputmonth = this.dataFormat(firstDay2);
                this.getData(1,10);
            }
        },
        getData(curr,row,id) {
            let radio;
            var input
            if (this.radio == 'year') {
                input = this.inputyear;
                radio = 3
            } else if (this.radio == 'month') {
                input = this.inputmonth;
                radio = 2
            } else if (this.radio == 'week') {
                input = this.inputweek;
                radio = 1
            } else if (this.radio == 'quarter') {
                input = this.inputquarter
            }
            this.http.post('/admin.statistics/categoryTop', {
                type: radio,
                date: input,
                curr,
                row,
                cate_id:id?id:'all',
                // start:this.start,
                // end:this.end
            }).then(re => {
                this.detail = re.data;
            })
        },
        dataFormat(time) {
            return `${time.getFullYear()}-${time.getMonth() + 1 >= 10 ? (time.getMonth() + 1) : '0' + (time.getMonth() + 1)}-${time.getDate() >= 10 ? time.getDate() : '0' + time.getDate()}`;
        },
        //翻页
        currentChange: function(curr,row,id) {
            this.curr = curr;
            this.getData(curr,row,id);
        },
        //改变每页条数
        // sizeChange: function(row) {
        //     this.row = row;
        //     this.getData(curr,10);
        // },
    }
}
</script>
<style media="screen">
    section{
        height: 100%;
        border: 1px solid #eee;
        border-top: 0;
    }
    .nameD{
        font-size: 15px;
        color: #606266;
        font-weight: bold;
        text-align: center;
        line-height: 2;
    }
</style>
